import de from '~/i18n/locales/de.json'
import en from '~/i18n/locales/en.json'
import es from '~/i18n/locales/es.json'
import fr from '~/i18n/locales/fr.json'
import it from '~/i18n/locales/it.json'
import pt from '~/i18n/locales/pt.json'

export default {
  fallbackLocale: 'en',
  legacy: false,
  locale: 'en',
  messages: {
    de,
    en,
    es,
    fr,
    it,
    pt,
  },
}
